<template>
  <PageContainer :center-vertically="true">
    <PageTitle :register-screen="true">
      {{ trans('LABEL:PasswordUpdate') }}
    </PageTitle>
    <t-card variant="auth">
      <!-- password reset form -->
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <form
            ref="resetPasswordForm"
            @submit.prevent="handleFormSubmit"
            class="flex flex-col gap-y-3"
        >
          <FieldWrapper>
            <TextField
                name="email"
                field="email"
                rules="email|required"
                :placeholder="trans('LABEL:Email')"
                v-model="email"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
                isPassword
                field="password"
                rules="required|min:8|max:50"
                :placeholder="trans('LABEL:PasswordNew')"
                v-model="password"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
                isPassword
                field="password_confirmation"
                rules="required|confirmed:password"
                :placeholder="trans('LABEL:PasswordRetype')"
                v-model="password_confirmation"
            />
          </FieldWrapper>
          <div class="flex items-center justify-end">
            <Button
              variant="buttonRed"
              type="submit"
              :loading="passwordResetLoading"
              :disabled="invalid"
              :text="trans('BUTTON:Save')"
            />
          </div>
        </form>
      </ValidationObserver>
    </t-card>
  </PageContainer>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import router from "@/router";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";

export default {
  data () {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      token: '',
    }
  },
  components: {
    PageTitle,
    PageContainer,
    Button, TextField, FieldWrapper
  },
  computed: {
    ...mapGetters('account', ['passwordResetLoading'])
  },
  methods: {
    ...mapActions('account', ['resetPasswordRequest']),
    handleFormSubmit (e) {
      e.preventDefault();

      const { email, password, password_confirmation, token} = this;
      if (email) {
        if(this.$refs.form.validate()) {
          this.resetPasswordRequest({email, password, password_confirmation, token})
        }
      }
    },
    checkRouteParams() {
      const routeQuery = this.$route.query;
      if(routeQuery.token === undefined || routeQuery.token === '') {
        router.push({name: 'auth-login'});
        return false;
      }
      this.email = routeQuery.email;
      this.token = routeQuery.token;
    },
  },
  mounted() {
    this.checkRouteParams();
  }
}
</script>

<style lang="scss">
</style>
